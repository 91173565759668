import auth0 from 'auth0-js'
import Vue from 'vue'
import { Trans } from '../plugins/Translation'

// Select redirectUri
// Setup output if is on development or production part
let redirectUri = Trans.getUserLocale().locale.includes('en') ? `https://localhost:8080/en/callback` : `https://localhost:8080/es/callback`;
if(process.env.NODE_ENV==='production') {
    redirectUri = Trans.getUserLocale().locale.includes('en') ? `https://www.bextsocial.com/en/callback` : `https://www.bextsocial.com/es/callback`;
}

// exchange the object with your own from the setup step above.
let webAuth = new auth0.WebAuth({
    domain: "dev-g9scl7fu.us.auth0.com",
    clientID: "d1hBewQQYLBnxp95ozFhsCV7XanPNDQB",
    redirectUri:redirectUri,
    audience: "https://dev-g9scl7fu.us.auth0.com/userinfo",
    responseType: "token id_token",
    scope: "openid email profile "
})
let auth = new Vue({
    computed: {
        token: {
            get: function() {
                return localStorage.getItem('id_token')
            },
            set: function(id_token) {
                localStorage.setItem('id_token', id_token)
            }
        },
        accessToken: {
            get: function() {
                return localStorage.getItem('access_token')
            },
            set: function(accessToken) {
                localStorage.setItem('access_token', accessToken)
            }
        },
        expiresAt: {
            get: function() {
                return localStorage.getItem('expires_at')
            },
            set: function(expiresIn) {
                let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime())
                localStorage.setItem('expires_at', expiresAt)
            }
        },
        user: {
            get: function() {
                return JSON.parse(localStorage.getItem('user'))
            },
            set: function(user) {
                localStorage.setItem('user', JSON.stringify(user))
            }
        }
    },
    methods: {
        login() {
            webAuth.authorize()
        },
        logout() {
            return new Promise((resolve, reject) => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('id_token');
                localStorage.removeItem('expires_at');
                localStorage.removeItem('user');
                localStorage.removeItem("rest");
                localStorage.removeItem("vuex");
                // Setup output if is on development or production part
                let returnTo1 = Trans.getUserLocale().locale.includes('en') ? `https://localhost:8080/en` : `https://localhost:8080/es`;
                if(process.env.NODE_ENV==='production') {
                    returnTo1 = Trans.getUserLocale().locale.includes('en') ? `https://www.bextsocial.com/en` : `https://www.bextsocial.com/es`;
                }
                webAuth.logout({
                    returnTo: returnTo1,
                    // returnTo: Trans.getUserLocale().locale.includes('en') ? "https://localhost:8080/en" : "https://localhost:8080/es",
                    clientID: 'd1hBewQQYLBnxp95ozFhsCV7XanPNDQB', // Your client ID
                })
            })
        },
        isAuthenticated() {
            return new Date().getTime() < this.expiresAt
        },
        handleAuthentication() {
            return new Promise((resolve, reject) => {
                webAuth.parseHash((err, authResult) => {

                    if (authResult && authResult.accessToken && authResult.idToken) {
                        this.expiresAt = authResult.expiresIn
                        this.accessToken = authResult.accessToken
                        this.token = authResult.idToken
                        this.user = authResult.idTokenPayload
                        resolve()

                    } else if (err) {
                        this.logout()
                        reject(err)
                    }

                })
            })
        }
    }
})

export default {
    install: function(Vue) {
        Vue.prototype.$auth = auth
    }
}