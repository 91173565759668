import axios from 'axios';
export default {
    namespaced: true,
    state: {
        darkblack: false,
    },
    mutations: {
        ligth1(state, comp){
            comp.classList.remove("dark");
            state.darkblack = false;
        },
        async dark(state, value){
          value.elemento.classList.add("dark");
          state.darkblack = true;
          try {
            const vuex = JSON.parse(localStorage.getItem("vuex"));
            const empresa =  vuex.social.filter(elem => elem.view == true);
            let cerrar1;
            value.cerrar == true ? cerrar1 = 1 : cerrar1 = 0;
            const response =  await axios.post(`/bextsocial/personalition`, {
                empresa: empresa[0].Id_Empresa,
                dark: 1,
                menu: cerrar1,
                bckmenu: '',
                bcknav: '',
            })
            console.log(response);
         } catch (error) {
             console.log(error)
         }
        },
        modeDark(state, that){
          if(state.darkblack){
            that.$parent.$parent.$el.classList.add("dark")
          }
        },
        modeDark2(state, that){
          state.darkblack = true
          that.classList.add("dark")
          
        },
    },
    actions: {

    },
    getters: {

    }
}