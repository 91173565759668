import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '../router'
import  createPersistedState  from  'vuex-persistedstate'
Vue.use(Vuex)

    //Modules
import vision from "../modules/vision";
import user from "../modules/user";
import menciones from '../modules/menciones';
import colors from '../modules/colors';
import dark from '../modules/dark';
import potenciar from '../modules/potenciar';
export default new Vuex.Store({
    state: {
        close: true,
        resource: [],
        usermencion: null,
        layout: 'login',
        users: null,
        loading: false,
        cuentas: false,
        social: null,
        socialFacebook: false,
        socialInstagram: false,
        socialTwitter: false,
        idPage: null,
        enhance: false,
        responsiveMenu: false
    },
    mutations: {
        CerrarExpandir(state) {
            if (state.close == true) {
                state.close = false;
            } else {
                state.close = true;
            }
        },
        getUserMencion(state, n) {
            console.log(state, n);
            debugger
            n.path.forEach(x => {
                if (x.tagName == "LI") {
                    state.usermencion = x.childNodes[0].childNodes[1].childNodes[1].textContent
                    console.log(x.childNodes[0].childNodes[1].childNodes[2].textContent)
                }
            })
        },
        SET_LAYOUT(state, payload) {
            state.layout = payload
        },
        Carga(state){
            if (state.loading == true) {
                state.loading = false;
            } else {
                state.loading = true;
            }
        },
        Counts(state, value){
            state.cuentas = value 
        },
        // Varibales globales de las redes sociales 
        socialWeb(state, value){
            if(value != false){
                state.social = value;
                const arraySocial = value[0].socialweb;
                arraySocial.forEach(item => {
                    if(item.Red_Social == "Facebook"){
                        state.socialFacebook = true;
                    }
                    else if(item.Red_Social == "Instagram"){
                        state.socialInstagram = true;
                    }
                    else{
                        state.socialTwitter = true;
                    }
                })
            }
            else{
                state.social = false
                state.socialFacebook = false;
                state.socialInstagram = false;
                state.socialTwitter = false;
            }
            
        },
        // NO recuerdo
        redesWeb(state, {value, red}){
            if(red == "Facebook"){
                state.socialFacebook = value;
            }
            else if(red == "Instagram"){
                state.socialInstagram = value;
            }
            else{
                state.socialTwitter = value;
            }
        },
        // Cambiar la empresa que se tiene que ver
        changeCompany(state, company){
            debugger;
            // const company = this.social;
            state.social.forEach(x => {
                if(x.Id_Empresa == company){
                    if(x.view == false){
                        x.view = true;    
                    }
                    else{
                        x.view = false;
                    }
                }
            })
        },
        //! Agregar id page de facebook
        changeIdPage(state, value){
            state.idPage = value;
        },
        changePotenciar(state, value){
            state.enhance = value;
        },
        changeResponsive(state, value){
            state.responsiveMenu = value;
        },
    },
    actions: {
        Counts(context, value){
            context.commit('Counts', value)
        },
        socialWeb(context, value){
            context.commit('socialWeb', value)
        },
        redesWeb(context, {value, red}){
            console.log(red);
            context.commit('redesWeb', {value, red})
        },
        changeCompany(context, company){
            // debugger;
            // colors.actions.toggleListColors(context, company)
            console.log(colors.state)
            context.commit('changeCompany', company)
        },
        changeIdPage(context, value){
            context.commit('changeIdPage', value)
        },
        changePotenciar(context, value){
            context.commit('changePotenciar', value)
        },
        changeResponsive(context, value){
            context.commit('changeResponsive', value)
        }
    },
    getters: {
        layout(state) {
            return state.layout
        }
    },
    plugins: [createPersistedState()],
    modules: {
        menciones,
        vision,
        user,
        colors,
        dark,
        potenciar
    }
})