<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <button
        type="button"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        class="navbar-toggler navbar-toggler-right"
        @click="showMenu"
      >
        <i class="fas fa-bars"></i>
      </button>
      <!-- Button trigger modal -->
      <!-- <transition name="fade">
        <button type="button" class="change-account" data-toggle="modal" data-target="#exampleModal" v-if="social && enhance != true">
            {{$t('navbar.item1')}}
            <font-awesome-icon icon="angle-down" size="1x"/>
        </button>
      </transition> -->

      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="social">
        <div class="modal-dialog  modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{$t('navbar.modal.title')}}</h5>
              <router-link :to="$i18nRoute({name: 'Accounts'})" data-dismiss="modal" type="button">
                <font-awesome-icon icon="plus-square" size="1x"/>
                {{$t('navbar.modal.button1')}}
              </router-link>
            </div>
            <div class="modal-body">
              <div class="form-group p-3">
                <label for="search-company" class="d-none">Buscar empresa</label>
                <font-awesome-icon icon="search" size="1x" />
                <input id="search-company" class="form-control"/>
              </div>
              <div class="change-tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile1" role="tab" aria-controls="profile1" aria-selected="false">{{$t('navbar.modal.label2')}}</a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{$t('navbar.modal.label1')}}</a>
                  </li>
                </ul>
                <div class="form-control">
                  {{$t('navbar.modal.label3')}}
                </div>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab">
                    No information
                  </div>
                  <div class="tab-pane fade show active" id="profile1" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="form-group" v-for="socials in social" :key="socials.Id_Empresa">
                      <label :for="socials.Id_Empresa" class="label-cbx">
                        <input name="empresa" :id="socials.Id_Empresa" type="radio" @click="seeCompany(socials.Id_Empresa)" class="d-none" :checked="socials.view">
                        <div class="checkbox">
                          <svg width="20px" height="20px" viewBox="0 0 20 20"><path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path><polyline points="4 11 8 15 16 6"></polyline></svg>
                        </div> 
                        {{ socials.empresa }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" data-dismiss="modal">{{$t('navbar.modal.button2')}}</button>
              <button type="button" @click="routeSite">{{$t('navbar.modal.button3')}}</button>
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div class="form-group input-tag" v-if="!enhance">
          <div class="icon-search">
            <font-awesome-icon icon="search" size="1x" />
          </div>
          <vue-tags-input
            v-model="tag"
            :tags="tags"
            :placeholder="$t('navbar.item2')"
            @tags-changed="newTags => tags = newTags"
          />
          <!--<button :class="{'slaq' : this.tag.length >= 3 || this.tags.length != 0   }" class="btn btn-bextsocial" @click="saveRest" type="button">BUSCAR</button>-->
        </div>
       </transition>
      
      <div class="collapse navbar-collapse justify-content-end">
        <router-link :to="$i18nRoute({name: 'Potenciar'})" class="wizard-btn">{{$t('navbar.item3')}}</router-link>
        <ul class="navbar-nav ml-auto">
          <li class="dropdown nav-item notificacion" v-click-outside="closeDropDown">
            
            <a href="#" class="dropdown-toggle nav-link list-noti" :class="{'newnoti' : newnoti}" @click="toggleDropDown">
              <span>{{$t('navbar.item4')}}</span>
              <font-awesome-icon icon="bell" size="1x"/>
            </a>
            <ul class="dropdown-menu notify-drop" :class="{show:isOpen}">
              <div class="notify-drop-title">
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6"><span class="white--text fw-bold">{{$t('navbar.item4')}}</span></div>
                  <div class="col-md-6 col-sm-6 col-xs-6" v-if="this.alot"><span class="badge badge-pill badge-warning">{{ this.alot }}</span></div>
                </div>
              </div>
              <!-- end notify title -->
              <!-- notify content -->
              <div class="drop-content" v-if="loaded">
                <li :class="{'nileer' : !item.Leida }" class="media dropdown-item" v-for="(item, index) in notifications" :key="index">
                    <div class="noti-con">
                      <div class="notify-img">
                        <img v-if="item.Imagen" :src="item.Imagen" alt="Notificación">
                        <img v-else src="../../assets/image/robot.png" alt="Robot">
                      </div>
                      <div class="media-body">
                          <h5 class="action-title">{{ item.Notificacion }}</h5>
                          <p><span class="timing">{{ item.Fecha_creacion }}</span></p>                            
                      </div>
                      <div class="view-notifi">
                        <font-awesome-icon  v-if="!item.Leida" icon="eye" @click="updateNotification(item.Id_Notificacion, item.Id_Usuario)" size="1x" />
                        <font-awesome-icon  v-if="item.Leida" icon="eye-slash" size="1x" />
                      </div>
                    </div>
                </li>
              </div>
            </ul>
          </li>

          <li aria-haspopup="true" class="dropdown nav-item btn-rotate dropdown">
            <a data-toggle="dropdown" aria-haspopup="true" class="nav-link dropdown-toggle">
              <span id="user"> {{ this.nameuser }} </span>
              <font-awesome-icon icon="angle-down" size="1x"/>
            </a>
            <ul x-placement class="dropdown-menu dropdown-menu-right">
              <!--<a class="dropdown-item">Action</a>
              <a class="dropdown-item">Another action</a>-->
              <router-link :to="$i18nRoute({name: 'Perfil'})" class="dropdown-item">{{$t('navbar.perfil.item1')}}</router-link>
              <a class="dropdown-item" href="mailto:mercadeo@bextsocial.com" title="Contáctanos">{{$t('navbar.perfil.item2')}}</a>
              <a class="dropdown-item" title="Cerrar sesión" v-if="$auth.user" @click="$auth.logout()">{{$t('navbar.perfil.item3')}}</a>
            </ul>
          </li>
          <li class="nav-item">
            <div class="img-user" :style="'background-image: url('+pictureuser+')'"></div>
          </li>
        </ul>
      </div>
    </div>
    <modalpeq group="foo2" position="bottom left" />
  </nav>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'menciones/getField',
  mutationType: 'menciones/updateField',
});
import { mapState, mapActions, mapMutations } from "vuex";
import VueTagsInput from "@johmun/vue-tags-input";
import moment from "moment";
export default {
  name: "Nabvar",
  components: {
    VueTagsInput
  },
  data() {
    return {
      placeholder: "Ingrese una palabra",
      isOpen: false,
      alot: null,
      newnoti: false,
      notifications: null,
      loaded: false,
      items: [
        { mensaje: 'Foo' },
        { mensaje: 'Bar' }
      ],
    }
  },
  computed: {
    ...mapFields(['tags', 'tag']),
    ...mapState("user",["users", "pictureuser", "nameuser"]),
    ...mapState(["social", 'enhance']),
  },  
  created() {
    this.notificaciones();
    console.log(this.nameuser, this.enhance);
    if(this.$route.name != "Potenciar"){
        this.changePotenciar(false)
    }
  },
  methods: {
    ...mapActions(["changeCompany", 'changePotenciar', 'changeResponsive']),
    ...mapActions('colors', ['toggleListColors','darkColors']),
    ...mapMutations('colors', ['ligth1']),
    ...mapMutations('dark', ['modeDark2']),
    async seeCompany(company){
      if(this.social.length > 1){
        debugger;
        this.changeCompany(company)
        const {data} =  await this.axios.get(`/bextsocial/personalition/${company}`);
        if(data != false){
            if(data[0].Dark == 1){
              this.darkColors();
              this.modeDark2(this.$parent.$el) 
            } else{
              this.ligth1(this.$parent)
              this.toggleListColors(data)}
        }
        else{
            let colors = [];
            colors.push({
              color: 'primary',
              background_menu: 'primary'
            })
            this.toggleListColors(colors)
        }
      }
    },
    toggleDropDown(){
      this.isOpen = !this.isOpen
    },
    closeDropDown(){
      this.isOpen = false
    },
    routeSite(){
      if(this.$route.name == "Home" || this.$route.name == "Create Search"){
        const prueba = this.social.filter(element => element.view == true);
        if(prueba.length > 0){
          document.querySelector('button[data-dismiss="modal"]').click()
        }
        else{
          this.$hijonew({
              group: 'foo2',
              type: 'danger',
              title: "Advertencia",
              text: 'Por favor seleccione una cuenta',
              duration: 1000,
              speed: 1000
          })
        }
        
      }
      else{
        const prueba = this.social.filter(element => element.view == true);
        if(prueba.length > 0){
          document.querySelector('button[data-dismiss="modal"]').click()
        }
      }
    },
    async notificaciones(){
        const vuex = JSON.parse(localStorage.getItem("vuex"));
        const {data} = await this.axios.get(`/bextsocial/notificationsid/${vuex.user.users[0].Id_Usuario}`);
        if(data){
          this.notifications = data.reverse();
          let datos = this.notifications;
          moment.locale('es');
          datos.forEach((item, index) => {
            const hoy = moment(new Date());
            const subfecha = item.Fecha_creacion
            let fecha = moment(subfecha);
            fecha = hoy.diff(fecha, 'hours');
            if(fecha < 24){
              if(fecha < 1){
                fecha = moment(subfecha);
                fecha = hoy.diff(fecha, "minute")
                item.Fecha_creacion = "Hace "+ fecha + " minutos"
              }
              else if(fecha == 1){
                item.Fecha_creacion = "Hace "+ fecha + " hora"
              }
              else{
                item.Fecha_creacion = "Hace "+ fecha + " horas"
              }
            }
            else{
              fecha = moment(subfecha).format("DD MMMM, hh:mm a");
              fecha = fecha.split(",");
              fecha = fecha[0]+", a las"+fecha[1];
              item.Fecha_creacion = fecha;
            }
          })
          console.log(datos)
          const sinleer = datos.filter(element => element.Leida == 0)
          
          if(sinleer.length > 0){
            this.newnoti = true
            this.alot = sinleer.length+" new"  
          }
          else{
            this.newnoti = false;
            this.alot = false;
          }
          this.loaded = true;
        }
    },
    async updateNotification(noti, user){
      console.log(noti, user)
      const response = await this.axios.put("/bextsocial/notifications",{
        iduser: user,
        idnotification: noti,
        leida: 1,
      })
      
      await this.notificaciones();
    },
    showMenu(){
      this.changeResponsive(true)
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

