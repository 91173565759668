<template>
<div
  class="vue-modalnotify-group">
  <component
    :is="componentName"
    :name="animationName"
    @enter="enter"
    @leave="leave"
    @after-leave="clean"
  >
    <div
      v-for="item in active"
      class="modal-notify"
      :style="notifyWrapperStyle(item)"
      :key="item.id"
      :data-id="item.id"
    >
      <slot
        name="body"
        :class="[classes, item.type]"
        :item="item"
        :close="() => destroy(item)"
      >
        <div class="modal-mask">
          <div class="modal fade" id="modalNotifyGeneral" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
            aria-hidden="true" style="display: block" :class="notifyClass(item)"
            @click="destroyIfNecessary(item)">
            
            <div class="modal-dialog modal-dialog-centered modal-notify"  role="document" style="background: transparent;">
                <!--Content-->
                <div class="modal-content">
                    <!--Header-->
                    <div class="modal-header">
                        <h4 class="modal-title info-color w-100 font-weight-bold" v-html="item.title"></h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"  class="white-text">&times;</span>
                        </button>
                    </div>

                    <!--Body-->
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-12" v-html="item.text">
                                <!--<img [src]="modalNotifyType.Image" alt=""
                                class="img-fluid z-depth-1-half rounded-circle">-->
                                <i style="font-size: 55px;"></i>
                            <!--<span *ngIf="html" [innerHtml]="html"></span>
                            <ng-content></ng-content>-->
                            
                            </div>
                            <div class="black" v-html="rawHtml">
                            </div>
                        </div>
                    </div>
                <!--Footer-->
                    <div class="modal-footer justify-content-center">
                        <a type="button"  class="btn-modal-notify btn modal-close" :class="'btn-'+item.type" data-dismiss="modal">Cerrar</a>
                        <!--<a type="button"  *ngIf="viewOk"  class="{{'btn-modal-notify btn btn-'+modalNotifyType.Class+''}}" (click)="close(true)">{{ok}} </a>
                        <a type="button"  *ngIf="viewExtra"  class="{{'btn-modal-notify btn btn-'+modalNotifyType.Class+''}}" (click)="close('extra')">{{extra}}</a>-->
                    </div>
                </div>
                <!--/.Content-->
            </div>
        </div>
        </div>
      </slot>
    </div>
  </component>
</div>
</template>
<script>
import plugin                         from './index'
import { events }                     from './events'
import { Id, split, listToDirection } from './util'
import defaults                       from './defaults'
// import VelocityGroup                  from './Hijo3.vue'
import ModalCustom2                       from './ModalCustom2.vue'
import parseNumericValue              from './parser'

const STATE = {
  IDLE: 0,
  DESTROYED: 2
}

const Component = {
  name: 'ModalCustom',
  components: {
    // VelocityGroup,
    ModalCustom2
  },
  props: {
    group: {
      type: String,
      default: ''
    },
    head: {
      type: Boolean,
      default: true
    },
    width: {
      type: [Number, String],
      default: 300
    },

    reverse: {
      type: Boolean,
      default: false
    },

    position: {
      type: [String, Array],
      default: () => {
        return defaults.position
      }
    },

    // classes: {
    //   type: String,
    //   default: 'vue-notification'
    // },

    animationType: {
      type: String,
      default: 'css',
      validator (value) {
        return value === 'css' || value === 'velocity'
      }
    },

    animation: {
      type: Object,
      default () {
        return defaults.velocityAnimation
      }
    },

    animationName: {
      type: String,
      default: defaults.cssAnimation
    },

    speed: {
      type: Number,
      default: 300
    },
    /* Todo */
    cooldown: {
      type: Number,
      default: 0
    },

    // duration: {
    //   type: Number,
    //   default: 3000
    // },

    delay: {
      type: Number,
      default: 0
    },

    max: {
      type: Number,
      default: Infinity
    },

    ignoreDuplicates: {
      type: Boolean,
      default: false
    },

    closeOnClick: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      list: [],
      velocity: plugin.params.velocity
    }
  },
  mounted () {
    events.$on('add', this.addItem);
    events.$on('close', this.closeItem);
  },
  computed: {
    actualWidth () {
      return parseNumericValue(this.width)
    },
    /**
      * isVelocityAnimation
      */
    isVA () {
      return this.animationType === 'velocity'
    },

    componentName () {
      
      return this.isVA
        ? 'VelocityGroup'
        : 'ModalCustom2'
    },

    styles () {
      const { x, y } = listToDirection(this.position)
      const width = this.actualWidth.value
      const suffix = this.actualWidth.type

      let styles = {
        width: width + suffix,
        [y]: '0px'
      }

      if (x === 'center') {
        styles['left'] = `calc(50% - ${width/2}${suffix})`
      } else {
        styles[x] = '0px'
      }

      return styles
    },

    active () {
      return this.list.filter(v => v.state !== STATE.DESTROYED)
    },

    botToTop () {
      return this.styles.hasOwnProperty('bottom')
    },
  },
  methods: {
    destroyIfNecessary (item) {
      
      if (this.closeOnClick) {
        this.destroy(item)
      }
    },
    addItem (event) {
      event.group = event.group || ''

      if (this.group !== event.group) {
        return
      }

      if (event.clean || event.clear) {
        this.destroyAll()
        return
      }

      const duration = typeof event.duration === 'number'
        ? event.duration
        : this.duration

      const speed = typeof event.speed === 'number'
        ? event.speed
        : this.speed

      const ignoreDuplicates = typeof event.ignoreDuplicates === 'boolean'
        ? event.ignoreDuplicates
        : this.ignoreDuplicates

      let { title, text, type, data, id } = event

      const item = {
        id: id || Id(),
        title,
        text,
        type,
        state: STATE.IDLE,
        speed,
        length: duration + 2 * speed,
        data
      }

      // if (duration >= 0) {
      //   item.timer = setTimeout(() => {
      //     this.destroy(item)
      //   }, item.length)
      // }

      let direction = this.reverse
        ? !this.botToTop
        : this.botToTop

      let indexToDestroy = -1

      const isDuplicate = this.active.some(item => {
        return item.title === event.title && item.text === event.text
      });

      const canAdd = ignoreDuplicates ? !isDuplicate : true;

      if (!canAdd) return;

      if (direction) {
        this.list.push(item)

        if (this.active.length > this.max) {
          indexToDestroy = 0
        }
      } else {
        this.list.unshift(item)

        if (this.active.length > this.max) {
          indexToDestroy = this.active.length - 1
        }
      }

      if (indexToDestroy !== -1) {
        this.destroy(this.active[indexToDestroy])
      }
    },

    closeItem (id) {
      
      this.destroyById(id)
    },

    notifyClass (item) {
      
      return [
        'show',
        // this.classes,
        item.type
      ]
    },

    notifyWrapperStyle (item) {
      return this.isVA
        ? null
        : { transition: `all ${item.speed}ms` }
    },

    destroy (item) {
      
      clearTimeout(item.timer)
      item.state = STATE.DESTROYED

      if (!this.isVA) {
        this.clean()
      }
    },

    destroyById (id) {
      
      const item = this.list.find(v => v.id === id)

      if (item) {
        this.destroy(item)
      }
    },

    destroyAll () {
      
      this.active.forEach(this.destroy)
    },

    getAnimation (index, el) {
      const animation = this.animation[index]

      return typeof animation === 'function'
        ? animation.call(this, el)
        : animation
    },

    enter ({ el, complete }) {
      const animation = this.getAnimation('enter', el)

      this.velocity(el, animation, {
        duration: this.speed,
        complete
      })
    },

    leave ({ el, complete }) {
      let animation = this.getAnimation('leave', el)

      this.velocity(el, animation, {
        duration: this.speed,
        complete
      })
    },

    clean () {
      this.list = this.list.filter(v => v.state !== STATE.DESTROYED)
    }
  }
}

export default Component
</script>
<style>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

</style>
