export default {
    namespaced: true,
    state: {
        potenciarstep: 1,
    },
    mutations: {
        changeStep(state, value){
            value == undefined ? state.potenciarstep = state.potenciarstep + 1 : state.potenciarstep = 1;
        },
    },
    actions: {
        changeStep(context,value){
            context.commit('changeStep', value)
        },
    },
    getters: {

    }
}