import Vue from 'vue'
import App from './App.vue'
import { i18n } from './i18n'
import router from './router'
import store from './store'
import { Trans } from './plugins/Translation'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import jQuery from "jquery";
// window.$ = window.jQuery = jQuery;
import VMdDateRangePicker from "v-md-date-range-picker";
import auth from './utils/auth'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';
import * as rules from 'vee-validate/dist/rules';
import vSelect from 'vue-select';
import Notifications from 'vue-notification';
import NewHijo from "./components/modalnotify/index";
import NewCustomModal from "./components/modalCustom/index";
import VtGenericFilter from "./components/datatables/VtGenericFilter.component";
import JsonExcel from "vue-json-excel";
// import VueGtag from "vue-gtag";


import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

Vue.component("downloadExcel", JsonExcel);

import GlobalDirectives from "./globalDirectives";
import "popper.js";
import "bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { getField, updateField } from 'vuex-map-fields';
library.add(fas, fab);
import { ClientTable } from 'vue-tables-2';
Vue.use(ClientTable, {}, false, 'bootstrap4', {
    genericFilter: VtGenericFilter
})


import "./assets/main.scss";

// Vue.use(VueGtag, {
//     config: { id: "G-W5Q6Y5CCD5" }
//   });

Vue.use(auth)
Vue.use(VueAxios, axios);
Vue.use(VMdDateRangePicker);
Vue.component('v-select', vSelect)
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.use(NewHijo);
Vue.use(NewCustomModal);
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize('es', es);

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// Agregamos la URL base de nuestra API
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// Check if is dev or prod environment
if(process.env.NODE_ENV==='production') {
    axios.defaults.baseURL = 'https://bextsocialapp.azurewebsites.net/api';
}
else {
    axios.defaults.baseURL = 'https://localhost:3000/api';
}

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("sidebar", require("./components/sidebar/Sidebar.component.vue").default);
Vue.component("navbar", require("./components/nabvar/Nabvar.component.vue").default);



// Add interceptor for Axios, to use the token for Authentication in requests.
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem("id_token");
        if (token) {
            config.headers.common["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')