<template>
  <div class="sidebar"  :class="[!$store.state.close ? 'sidebar-close' : '', $store.state.responsiveMenu ? 'ds' : '']" >
    <nav id="sidebar" class="sidebar-wrapper">
      <div class="sidebar-content">
        <div class="sidebar-brand">
          <router-link :to="$i18nRoute({name: 'Home'})" class="nav-link" style="font-size:20px" :class="{'d-o' : !$store.state.close }">
            <img src="../../assets/image/LOGOB.png" alt="Bextosocial" />
          </router-link>
          <font-awesome-icon icon="angle-left" size="3x" :class="{'svg-close' : !$store.state.close}" @click="cerrarClose()"/>
          <!-- <div id="close-sidebar">
            <font-awesome-icon icon="user-secret" />
          </div> -->
          <transition name="fade">
            <p class="p-sidebar" :class="{'d-o' : !$store.state.close }">{{this.computCompany}}</p>
          </transition>
        </div>        
        <div class="sidebar-menu">
          <transition name="fade">
          <ul v-if="!enhance">
            <li class="header-menu"><!--:active="pruebame"--> 
               <router-link :to="$i18nRoute({name: 'Dashboard'})" class="nav-link blue-light" style="font-size:20px">
                  <!--<font-awesome-icon icon="chart-bar" size="2x"/>-->
                  <i class="icon-icon-dashboard"></i>
                  <span class="item-link" :class="{'d-o' : !$store.state.close}" >{{$t('sidebar.item1')}}</span>
               </router-link>
            </li>
            <li class="header-menu"><!--:active="pruebame"--> 
               <router-link :to="$i18nRoute({name: 'Create Search'})" class="nav-link green-light" style="font-size:20px">
                  <!--<font-awesome-icon icon="search" size="2x"/>-->
                  <i class="icon-icon_search"></i>
                  <span class="item-link" :class="{'d-o' : !$store.state.close}" >{{$t('sidebar.item2')}}</span>
               </router-link>
            </li>
            <li class="header-menu"><!--:active="pruebame"--> 
               <router-link :to="$i18nRoute({name: 'Temas'})" class="nav-link purple-light" style="font-size:20px">
                  <i class="fas fa-record-vinyl"></i>
                  <!-- <font-awesome-icon :icon="['fab', 'fa-record-vinyl']" size="3x"/> -->
                  <span class="item-link" :class="{'d-o' : !$store.state.close}" >{{$t('sidebar.item3')}}</span>
               </router-link>
            </li>
            <li class="header-menu"><!--:active="pruebame"--> 
               <router-link :to="$i18nRoute({name: 'Competidores'})" class="nav-link native-light" style="font-size:20px">
                  <i class="fas fa-chart-pie"></i>
                  <span class="item-link" :class="{'d-o' : !$store.state.close}" >{{$t('sidebar.item4')}}</span>
               </router-link>
            </li>
            <li class="header-menu"><!--:active="pruebame"--> 
               <router-link :to="$i18nRoute({name: 'Estadisticas'})" class="nav-link native-light" style="font-size:20px">
                  <i class="fas fa-chart-pie"></i>
                  <span class="item-link" :class="{'d-o' : !$store.state.close}" >{{$t('sidebar.item5')}}</span>
               </router-link>
            </li>
            <li class="header-menu"><!--:active="pruebame"--> 
               <a class="item-sinlink nav-link yellow-light" style="font-size:20px">
                  <i class="fas fa-sync-alt"></i>
                  <span class="item-link" :class="{'d-o' : !$store.state.close}" >{{$t('sidebar.item6')}}</span>
               </a>
               <ul>
                 <li class="subheader-menu">
                   <router-link :to="$i18nRoute({name: 'Unlink'})" class="nav-link yellow-light" style="font-size:20px">
                        <span class="item-link" :class="{'d-o' : !$store.state.close}" >{{$t('sidebar.sub6item1')}}</span>
                    </router-link>
                 </li>
                 <li class="subheader-menu">
                   <router-link :to="$i18nRoute({name: 'Sync'})" class="nav-link yellow-light" style="font-size:20px">
                        <!-- <i class="fas fa-sync-alt"></i> -->
                        <span class="item-link" :class="{'d-o' : !$store.state.close}" >{{$t('sidebar.sub6item2')}}</span>
                    </router-link>
                 </li>
                 <li class="subheader-menu">
                   <router-link :to="$i18nRoute({name: 'Accounts'})" class="nav-link yellow-light" style="font-size:20px">
                        <!-- <i class="fas fa-sync-alt"></i> -->
                        <span class="item-link" :class="{'d-o' : !$store.state.close}" >{{$t('sidebar.sub6item3')}}</span>
                    </router-link>
                 </li>
               </ul>
            </li>
            <!--<li class="sidebar-dropdown">
              <router-link to="/menciones" class="nav-link" style="font-size:20px">
                <font-awesome-icon icon="comment-alt" size="2x"/>
                <span :class="{'d-o' : !$store.state.close}">Menciones</span>
                <span class="badge badge-pill badge-warning" :class="{'d-o' : !$store.state.close}" >New</span>
              </router-link>
              <div class="sidebar-submenu" style="display: none;">
                <ul class="no-padd list">
                  <li class="header-menu-sub">
                    <a href="#" class="nav-link" >
                      <span :class="{'d-o' : !$store.state.close}">Dashboard 1</span>
                      <span class="badge badge-pill badge-success">Pro</span>
                    </a>
                  </li>
                  <li class="header-menu-sub">
                    <a href="#" class="nav-link"><span :class="{'d-o' : !$store.state.close}">Dashboard 2</span></a>
                  </li>
                  <li class="header-menu-sub">
                    <a href="#" class="nav-link"><span :class="{'d-o' : !$store.state.close}">Dashboard 3</span></a>
                  </li>
                </ul>
              </div>
            </li>-->
            <!--<li class="header-menu">
               <router-link to="/estadisticas" class="nav-link" style="font-size:20px">
                  <font-awesome-icon icon="chart-bar" size="2x"/>
                  <span class="item-link" :class="{'d-o' : !$store.state.close}" >Estadísticas</span>
               </router-link>
            </li>-->
          </ul>
          <div class="enhance" v-else>
              <p>{{$t('sidebar.process')}}</p>
              <ul>
                <li :class="{'activestep' : potenciarstep > 0 }">
                  <span :class="potenciarstep == 1 ? 'fas fa-chevron-circle-right' : 'fas fa-check-circle'"></span>
                  <p>{{$t('sidebar.wizard1')}}</p>
                </li>
                <li :class="{'activestep' : potenciarstep > 1 }">
                  <span :class="potenciarstep == 2 ? 'fas fa-chevron-circle-right' : 'fas fa-check-circle'"></span>
                  <p>{{$t('sidebar.wizard2')}}</p>
                </li>
                <li :class="{'activestep' : potenciarstep > 2 }">
                  <span :class="potenciarstep == 3 ? 'fas fa-chevron-circle-right' : 'fas fa-check-circle'"></span>
                  <p>{{$t('sidebar.wizard3')}}</p>
                </li>
                <li :class="{'activestep' : potenciarstep > 3 }">
                  <span :class="potenciarstep == 1 ? 'fas fa-chevron-circle-right' : 'fas fa-check-circle'"></span>
                  <p>{{$t('sidebar.wizard4')}}</p>
                </li>
              </ul>
            </div>
          </transition>
        </div>
        <!-- sidebar-menu  -->
      </div>
    </nav>
    <!-- Button trigger modal -->
      <transition name="fade">
        <button type="button" v-if="social" class="change-account btn btn-sidebar" data-toggle="modal" data-target="#exampleModal">
            {{$t('navbar.item1')}}
            <font-awesome-icon icon="angle-down" size="1x"/>
        </button>
      </transition>
  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex';
export default {
  name: "Sidebar",
  data() {
    return{
    }
    
  },
  computed: {
    ...mapState(['enhance', 'social', 'responsiveMenu']),
    ...mapState('potenciar', ["potenciarstep"]),

    computCompany(){
      console.log("comput company");
      console.log(this.social);
      if(this.social != false && this.social != null){
        const name =  this.social.filter(item => item.view == true)
        if(name.length > 0){
          return name[0].empresa;
        }
        else{
          return 'Seleccionar empresa'
        }
      }
      else{
        return 'Seleccionar empresa'
      }
      
    }
  },
  created(){
    
  },
  methods:{
    ...mapActions(['changeResponsive']),
    cerrarClose(){
      if(this.responsiveMenu){
        this.changeResponsive(false)
      }else{
        $store.commit('CerrarExpandir')
      }
      
    }
    
  } , 
  mounted(){
    //todo: Es como el windows-onload
  }
};
</script>

