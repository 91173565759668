import axios from 'axios'
// import VueAxios from 'vue-axios'
export default {
    namespaced: true,
    state: {
        sidebarColors: [
            { color: 'primary', active: true, value: 'primary', img: '/Default-Theme.png' },
            { color: 'gray', active: false, value: 'gray', img: '/Gray-Theme.png' },
            { color: 'black', active: false, value: 'black', img: "/Black-Theme.png" },
            { color: 'red', active: false, value: 'red' , img: "/Red-Theme.png" },
            { color: 'blue', active: false, value: 'blue', img: "/Blue-Theme.png" },
            { color: 'purple', active: false, value: 'purple', img: "/Purple-Theme.png" },
            { color: 'green', active: false, value: 'green', img: "/Green-Theme.png" }
            // { color: 'success', active: false, value: 'green' }
          ],
    },
    mutations: {
        ligth1(state, comp){
            comp.$el.classList.remove("dark");
            state.darkblack = false;
        },
        darkColors(state){
            state.sidebarColors.forEach((listItem) => {
                listItem.active = false
            });
            state.sidebarColors[2].active = true
        },
        async toggleList(state, value) {
            state.sidebarColors.forEach((listItem) => {
              listItem.active = false
            });
            value.item.active = true
             try {
                const vuex = JSON.parse(localStorage.getItem("vuex"));
                const empresa =  vuex.social.filter(elem => elem.view == true);
                let cerrar1, black;
                value.cerrar == true ? cerrar1 = 1 : cerrar1 = 0;
                value.item == "black" ? black = 1 : black = 0
                const response =  await axios.post(`/bextsocial/personalition`, {
                    empresa: empresa[0].Id_Empresa,
                    dark: black,
                    menu: cerrar1,
                    bckmenu: value.item.value,
                    bcknav: value.item.value,
                })
                console.log(response)
             } catch (error) {
                 console.log(error)
             }
        },
        toggleListColors(state, value){
            const item = state.sidebarColors.filter(elem => elem.color == value[0].background_menu)
            state.sidebarColors.forEach((listItem) => {
                listItem.active = false
            });
            item[0].active = true;
        },
    },
    actions: {
        toggleList(context, value){
            console.log(value);
            context.commit('toggleList', value)
        },
        async toggleListColors(context, value){
            context.commit('toggleListColors', value)
        },
        darkColors(context){
            context.commit('darkColors')
        },
    },
    getters: {

    }
}