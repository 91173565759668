<template>
  <transition-group :name="name">
    <slot/>
  </transition-group>
</template>
<script>
export default {
  name: 'modalcustom2',
  props: ['name']
}
</script>
