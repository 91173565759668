import ModalCustom from './ModalCustom.vue'
import { events }    from './events'

const Customnotify = {
  install(Vue, args = {}) {
    if (this.installed) {
      return
    }

    this.installed = true
    this.params = args

    Vue.component(args.componentName || 'custommodal', ModalCustom)

    const customnew = (params) => {
      if (typeof params === 'string') {
        params = { title: '', text: params }
      }

      if (typeof params === 'object') {
        events.$emit('add', params)
      }
    }

    customnew.close = function (id) {
      events.$emit('close', id)
    }

    const name = args.name || 'customnew'

    Vue.prototype['$' + name] = customnew
    Vue[name] = customnew
  }
}

export default Customnotify
