import Hijo1 from './ModalNotify.vue'
import { events }    from './events'

const Hijonotify = {
  install(Vue, args = {}) {
    if (this.installed) {
      return
    }

    this.installed = true
    this.params = args

    Vue.component(args.componentName || 'modalpeq', Hijo1)

    const hijonew = (params) => {
      if (typeof params === 'string') {
        params = { title: '', text: params }
      }

      if (typeof params === 'object') {
        events.$emit('add', params)
      }
    }

    hijonew.close = function (id) {
      events.$emit('close', id)
    }

    const name = args.name || 'hijonew'

    Vue.prototype['$' + name] = hijonew
    Vue[name] = hijonew
  }
}

export default Hijonotify
