<template>
    <div id="loading">
        <div class="rin">
            <!--<img src="../../assets/image/loader.gif" />-->
            <div class="boxes">
        <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <!-- dribbble -->
            </div>
    </div>
</template>
<script>
export default {
    name: "loading",
    data() {
        return {
            
        }
    },
}
</script>