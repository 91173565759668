import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '../router'
import { getField, updateField } from 'vuex-map-fields';
// import { createHelpers } from 'vuex-map-fields';

// Because by default, getters and mutations in Vuex
// modules, are globally accessible and not namespaced,
// you most likely want to rename the getter and mutation
// helpers because otherwise you can't reuse them in multiple,
// non namespaced modules.
// const { getFooField, updateFooField } = createHelpers({
//   getterType: 'getFooField',
//   mutationType: 'updateFooField',
// });
export default {
    namespaced: true,
    state: {
        tags: [],
        rest: "",
        tag: "",
        //redes: [],
    },
    mutations: {
        updateField,
        
    },
    actions: {

    },
    getters: {
        // getFooField
        getField,
    }
}