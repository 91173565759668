const directions = {
  x: ['left', 'center', 'right'],
  y: ['top', 'bottom']
}

/**
  * Sequential ID generator
  */
export const Id = (i => () => i++)(0)

/**
  * Splits space/tab separated string into array and cleans empty string items.
  */
export const split = (value) => {
  if (typeof value !== 'string') {
    return []
  }

  return value.split(/\s+/gi).filter(v => v)
}

/**
  * Cleanes and transforms string of format "x y" into object {x, y}. 
  * Possible combinations:
  *   x - left, center, right
  *   y - top, bottom
  */
export const listToDirection = (value) => {
  if (typeof value === 'string') {
    value = split(value)
  }

  let x = null
  let y = null

  value.forEach(v => {
    if (directions.y.indexOf(v) !== -1) {
      y = v
    }
    if (directions.x.indexOf(v) !== -1) {
      x = v
    }
  })

  return { x, y }
}

export const ModalNotifyTypes = {
  Success: { Id: 1, Icon: 'fas fa-check-circle fa-w-16 fa-7x', Image: '', Class: 'success',  },
  Info: { Id: 2, Icon: 'fas fa-info-circle fa-w-16 fa-5x', Image: '', Class: 'info' },
  Warning: { Id: 3, Icon: 'fas fa-exclamation-triangle fa-w-16 fa-5x', Image: '', Class: 'warning' },
  Danger: { Id: 4, Icon: 'fas fa-exclamation-circle fa-w-16 fa-5x', Image: '', Class: 'danger' },
  Primary: { Id: 5, Icon: '', Image: '', Class: 'primary' },
  Secondary: { Id: 6, Icon: '', Image: '', Class: 'secondary' },
  Light: { Id: 6, Icon: 'far fa-sticky-note fa-w-16 fa-5x', Image: '', Class: 'light' },
  Dark: { Id: 6, Icon: 'fas fa-sticky-note fa-w-16 fa-5x', Image: '', Class: 'dark' },
  Link: { Id: 6, Icon: 'fas fa-link fa-w-16 fa-5x', Image: '', Class: 'link' },
  Question: { Id: 7, Icon: 'fas fa-question-circle fa-w-16 fa-5x', Image: '', Class: 'primary' },
  QuestionWarning: { Id: 8, Icon: 'fas fa-question-circle fa-w-16 fa-5x', Image: '', Class: 'warning' },
  QuestionDanger: { Id: 9, Icon: 'fas fa-question-circle fa-w-16 fa-5x', Image: '', Class: 'danger' },
}