import router from '../router';
import moment from "moment";
// import { getField, updateField } from 'vuex-map-fields';
// import { createHelpers } from 'vuex-map-fields';


// todo siempre colocar namespaced cuando se utilicen modulos en vuex
// // Because by default, getters and mutations in Vuex
// // modules, are globally accessible and not namespaced,
// // you most likely want to rename the getter and mutation
// // helpers because otherwise you can't reuse them in multiple,
// // non namespaced modules.
import { getField, updateField } from 'vuex-map-fields';
// var objetoRedes = datos;
var inicio = new Date()
var despe = new Date(inicio.setDate(inicio.getDate() - 30))
inicio = new Date(inicio.setDate(inicio.getDate() + 30))
inicio = moment(inicio).format('YYYY-MM-DD HH:MM')
despe = moment(despe).format('YYYY-MM-DD HH:MM')
export default {
    namespaced: true,
    
    state: {
        dateRange: {
            startDate: despe,
            endDate: inicio,
        },
        fechas: null
    },
    mutations: {
        // updateFooField,
        updateField,
        dateNow(state) {
            let fechas = [];
            const now = new Date();
            let another = new Date();
            another = another.setDate(another.getDate() - 8);
            const startvue = moment(now, "YYYYMMDD").format("MM-DD-yyyy");
            const endvue = moment(another, "YYYYMMDD").format("MM-DD-yyyy");
            const startvuex = moment(state.dateRange.startDate, "YYYYMMDD").format("MM-DD-yyyy");
            const endvuex = moment(state.dateRange.endDate, "YYYYMMDD").format("MM-DD-yyyy");

            if (startvue == startvuex && endvue == endvuex) {
                fechas.push({
                    result: false
                })
            } else {
                fechas.push({
                    result: true,
                    start: startvuex,
                    end: endvuex
                })
            }
            state.fechas = fechas;
        }

    },
    actions: {

    },
    getters: {
        // getFooField
        getField,
    }
}