import axios from 'axios'
import VueAxios from 'vue-axios'

export default {
    namespaced: true,
    state: {
        users: null,
        pictureuser: null,
        nameuser: "",
        company: null,
        logged: false
    },
    mutations: {
        prueba(state, userActions) {
            state.users = userActions;
            let user = JSON.parse(localStorage.getItem("user"));
            state.pictureuser = user.picture;
        },
        changePicture(state, picture){
            state.pictureuser = picture;
        },
        changeUser(state, username){
            state.nameuser = username;
        },
        uploadCompany(state, value){
            state.company = value;
        },
        uploadLogged(state, value){
            state.logged = value;
        },
    },
    actions: {
        loadUser(context) {
            let user = JSON.parse(localStorage.getItem("user"));
            return new Promise(( res, rej ) => {
                axios.get(`/bextsocial/userid/${user.email}`)
                .then(result => {
                    context.commit("prueba", result.data);
                    res();
                })
                .catch(() => rej());
            });
        },
        uploadCompany(context, value){
            context.commit('uploadCompany', value)
        },
        uploadLogged(context, value){
            context.commit('uploadLogged', value)
        },
        async getRedes(){
            let user = JSON.parse(localStorage.getItem("vuex"));
            user = user.user.users[0].Id_Usuario
            const pruebaresponse =  await axios.get(`/bextsocial/cuenta/${user}`)
            if(pruebaresponse.data != false){
                let result = [];
                let map = new Map();
                debugger;
                pruebaresponse.data.forEach((item, index) => {
                    if(!map.has(item.nombre)){
                        map.set(item.nombre, true); 
                        // set any value to Map maoero
                        result.push({
                            Id_Empresa: item.Id_Empresa,
                            empresa: item.nombre,
                            pais: item.pais,
                            departamento: item.departamento,
                            ciudad: item.ciudad,
                            telefono: item.telefono,
                            contacto: item.contacto,
                            correo: item.correo,
                            view: item.view_empresa,
                            socialweb: new Array()
                        });
                        if(result.length == 1){
                            result[0].socialweb.push({
                                Id_Usuario: item.Id_Usuario,
                                Red_Social: item.Red_Social
                            })
                        }
                        else{
                            let indextmp = null;
                            let arr = result.filter(function(x,i){if(x.empresa == item.nombre){indextmp = i}} )
                            result[indextmp].socialweb.push({
                                Id_Usuario: item.Id_Usuario,
                                Red_Social: item.Red_Social
                            });
                        }
                    }
                    else 
                    {  
                        let indextmp = null;
                        let arr = result.filter(function(x,i){if(x.empresa == item.nombre){indextmp = i}} )
                        result[indextmp].socialweb.push({
                            Id_Usuario: item.Id_Usuario,
                            Red_Social: item.Red_Social
                        });
                    }
                })
                return result;
            }
            else{
                return false
            }
        },
    },
    getters: {
        // getFooField
        //getField,
    }
}