<template>
  <div id="bextSocial">
  <loading v-if="loading"></loading>
     <router-view></router-view>
  </div>
</template>
<script>
import Loading from "@/components/loading/Loading.component.vue";
import {mapState} from "vuex";
export default {
  name: "App",
  components:{
      Loading
  },
  computed: {
      ...mapState(["loading"])
  },
  mounted(){
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/613a36f025797d7a89fe2343/1ff5md7sd';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .main-panel{
    margin-left: 260px;
    background: #F0F0F7;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transition: all .5s cubic-bezier(.685,.0473,.346,1);
        transition: all .5s cubic-bezier(.685,.0473,.346,1);
    padding-bottom: 10px;
    &.main-close{
      margin-left: 66px;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>