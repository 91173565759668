import Vue from "vue";
import VueRouter from "vue-router";
import multiguard from 'vue-router-multiguard';
import store from '../store'
import { Trans } from '@/plugins/Translation'

function load(component){
    return () => import(`@/views/${component}.vue`)
}

Vue.use(VueRouter);

function requireAuth(to, from, next) {
    if (!router.app.$auth.isAuthenticated()) {
        next({
            name: "NotAuthorized"
        })
    } else {
        next();

    }
}
function syncCounts(to, from, next) {
    if(!store.state.cuentas){
        next({
            name: "Accounts"
        })
        // alert("Error primero debe sincronizar alguna red social");
    }
    else{
        next();
    }
}
function syncCompany(to, from, next) {
    let companyView = store.state.social;
    companyView = companyView.filter(element => element.view == true)
    if(companyView.length > 0){
        next();
    }
    else{
        next({
            path: "/:locale/home"
        })
    }
}
const routes = [
    {
        path: "/:locale",
        component: () =>
            import ( /* webpackChunkName: "home" */ "../layouts/Landing.master.vue"),
        beforeEnter: Trans.routeMiddleware,  
        children: [{
            path: "",
            name: "Landing",
            component: load('Landing'),
        }]
    },
    {
        path: "/:locale/precios",
        component: () =>
            import ( /* webpackChunkName: "home" */ "../layouts/Login.vue"),
        children: [{
            path: "",
            name: "Precios",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Precios.vue"),
        }]
    },
    {
        path: "/:locale/about",
        beforeEnter: requireAuth,
        name: "About",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/About.vue")
    },
    {
        path: "/:locale/sincronizacion-de-cuentas",
        beforeEnter: requireAuth,
        component: () =>
            import ( /* webpackChunkName: "home" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Sincronizacion",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Cuentas.vue"),
        }]
    },
    {
        //!multiguard lo utlizamos para los otras páginas excepto la de sincronización de cuentas ya 
        //!que al incluirla mostrará un mensaje de error en la navegación
        //!Entonces se controla directamente desde la vista con el beforeRouteLeave
        path: "/:locale/synchronizing-accounts",
        //beforeEnter: multiguard([requireAuth, syncCounts]),
        beforeEnter: requireAuth,
        component: () =>
            import ( /* webpackChunkName: "home" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Accounts",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Accounts.vue"),
        }]
    },
    {
        path: "/:locale/home",
        beforeEnter: multiguard([requireAuth, syncCounts]),
        component: () =>
            import ( /* webpackChunkName: "home" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Home",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Home.vue"),
        }]
    },
    {
        path: "/:locale/create-search",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Create Search",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/CreateSearch.vue"),
        }]
    },
    {
        path: "/:locale/dashboard",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Dashboard",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Dashboard.vue"),
        }]
    },
    {
        path: "/:locale/stadistics",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Estadisticas",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Estadisticas.vue")
        }]
    },
    {
        path: "/:locale/menciones/:tipo",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Menciones",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Menciones.vue")
        }]
    },
    {
        path: "/:locale/profile",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Perfil",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Profile.vue")
        }]
    },
    {
        path: "/:locale/themes",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Temas",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Themes.vue")
        }]
    },
    {
        path: "/:locale/competitors",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Competidores",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Competitors.vue")
        }]
    },
    {
        path: "/:locale/enhance",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "/:locale/enhance",
            name: "Potenciar",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Potenciar.vue")
        }]
    },
    {
        path: "/:locale/sync",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "/:locale/sync",
            name: "Sync",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/TableSync.vue")
        }]
    },
    {
        path: "/:locale/sync-redes/:id",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "",
            name: "Sync Redes",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Sync.vue")
        }]
    },
    {
        path: "/:locale/unlink",
        beforeEnter: multiguard([requireAuth, syncCounts, syncCompany]),
        component: () =>
            import ( /* webpackChunkName: "about" */ "../layouts/Aplication.vue"),
        children: [{
            path: "/:locale/unlink",
            name: "Unlink",
            component: () =>
                import ( /* webpackChunkName: "about" */ "../views/Unlink.vue")
        }]
    },
    {
        path: "/:locale/secret",
        name: "Secret",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Secret.vue")
    },
    {
        path: "/:locale/callback",
        name: "Callback",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Callback.vue")
    },
    {
        path: "/:locale/not-authorized",
        name: "NotAuthorized",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/NotAuthorized.vue")
    },
    {
        path: '*',
        redirect() {
          return Trans.getUserLocale().locale.includes('en') ? "en" : "es";
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

// very basic "setup" of a global guard
// router.beforeEach((to, from, next) => {
//     if (to.name == 'Callback') { // check if "to"-route is "callback" and allow access
//         next()
//     }

//     if (router.app.$auth.isAuthenticated()) { // if authenticated allow access
//         next()
//     } else { // trigger auth0 login
//         next()
//     }
// })

export default router;